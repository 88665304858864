import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  HostListener,
  ViewEncapsulation,
  AfterViewInit,
} from "@angular/core";
import {
  ModalService,
  HeaderService,
  GoogleService,
  SideMenuService,
  SideMenuState,
} from "../../services";
import Modals from "../../../assets/data/base/modals.base.json";
import videos from "../../../assets/data/base/videos.base.json";
import { environment } from "src/environments/environment";
import * as GTM from "../../utils/GTM";
import { DomSanitizer } from "@angular/platform-browser";
import { Modal } from "src/app/models";

@Component({
  selector: "app-modal",
  templateUrl: "./modal.component.html",
  styleUrls: ["./modal.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ModalComponent implements OnInit {
  @ViewChild("modal-contain") _modal: ElementRef;

  @ViewChild("pdfViewer")
  set pdfViewer(viewer: any) {
    if (viewer && viewer.iframe) {
      viewer.iframe.nativeElement.addEventListener("load", (ev) => {
        if (!this.modalData.hide_user_controls) return;
        const new_style_element = document.createElement("style");
        new_style_element.textContent =
          "#viewerContainer { overflow: hidden; } #toolbarViewerLeft, #secondaryToolbarToggle, #verticalToolbarSeparator { display: none; }";
        ev.target.contentDocument.head.appendChild(new_style_element);
      });
    }
  }

  public redirectUrl: string = "https://www.takeda.com/";
  public modalVisible: boolean = false;
  public path: string = environment.assetBasePath + "pdfs/";
  public modalData: Modal = undefined;
  public activeVideo: string = undefined;
  public showPdf: boolean = false;
  public pdfFilesCount: number = 1;
  public currentPdfFileNumber: number = 0;
  public iframeSource: any = "";
  public headState: string = "";
  public menuState: string = "";
  public twilioChatRef: any;

  // Used when exiting child modal to parent modal:
  // Skip sending next google analytics event on modal open.
  public skipNextGA: boolean = false;

  constructor(
    private modalSvc: ModalService,
    private headerSvc: HeaderService,
    private menuSvc: SideMenuService,
    private _sanitizer: DomSanitizer,
    private googleSvc: GoogleService
  ) { }

  ngOnInit(): void {
    this.twilioChatRef = window["twilioChat"];
    this.subscribe();
  }

  // @HostListener("document:keypress", ["$event"])
  // public handleKeyboardEvent(ev: KeyboardEvent) {
  //   if (ev.key === "q") {
  //     this.closeClick(this.modalData?.parent);
  //   }
  // }

  public openExternalLinksInNewTab(event: any): void {
    console.log(event, event.target, "is the PDF external link event");
    document
      .getElementById("pdfViewer")
      .getElementsByTagName("iframe")[0]
      .contentDocument.getElementById("viewerContainer")
      .addEventListener("click", (event: any) => {
        if (
          event.target.nodeName === "A" &&
          !event.target.classList.contains("internalLink")
        ) {
          event.preventDefault();
          // @analytics
          this.googleSvc.sendExternalLinkEvent(event.target.innerText, event.target.href);
          console.log("An external link click", event);
          window.open(event.target.href, "_blank");
        }
      });
  }

  public subscribe(): void {
    this.headerSvc.headerState.subscribe((data) => {
      this.headState = data;
    });

    this.menuSvc.sideMenuState.subscribe((data) => {
      this.menuState = data;
    });

    this.modalSvc.modalVisible.subscribe((data) => {
      if (this.modalVisible !== data) {
        this.modalVisible = data;

        if (this.modalVisible) {
          if (this.modalData?.gtm_id) {
            GTM.sendGTMEvent(this.modalData.gtm_id);
          }
        }
      } // END IF

      if (this.twilioChatRef) {
        if (window["jQuery"]) {
          this.modalVisible
            ? this.twilioChatRef.close()
            : this.twilioChatRef.open();
        }
      } // END IF
    });

    this.modalSvc.modalData.subscribe((data) => {
      if (this.modalData !== data) {
        if (data) {
          this.modalData = data;

          if (this.modalData?.modal_type === "iframe") {
            this.iframeSource = this._sanitizer.bypassSecurityTrustResourceUrl(
              this.modalData.src
            );
          }

          if (this.modalData?.modal_type === "html-tak-sponsored") {
            setTimeout(() => {
              let buttons = document.getElementsByClassName("pdf-button");
              console.log(buttons);
              console.log(buttons.item(0));
              if(this.menuSvc.sideMenuState.value !== SideMenuState.OPEN) {
                this.menuSvc.setSideMenuState(SideMenuState.OPEN);
              }

              (buttons.item(0) as HTMLElement).onclick = (e) => {
                e.preventDefault();
                this.openModal("about.posters");
                this.menuSvc.setSideMenuState(SideMenuState.CLOSED);
                return false;
              }
            }, 100);
          }

          if (this.modalData?.modal_type === "pdf") {
            this.showPdf = false;
            setTimeout(() => {
              console.log("in setTimeout in subcribe pdf");
              // @analytics
              this.googleSvc.sendGAEventByData({
                  "event": "pdfView",
                  "eventCategory": this.modalData.title,
                  "eventAction": this.modalData.src
              });
              this.showPdf = true;
            }, 1);

            if (this.modalData.src_set)
              this.pdfFilesCount = this.modalData.src_set.length;
          }

          if (this.modalData?.modal_type === "video-player") {
            setTimeout(() => {
              console.log("in setTimeout in subcribe video-player");
              // @analytics
              let aevent = null;
              if (this.modalData.gtmTitle) {
                aevent = {
                  "event": "videoView",
                  "eventCategory": this.modalData.title,
                  "eventAction": this.modalData.gtmTitle,
                  "eventLabel": this.modalData.src
                };
              } else {
                aevent = {
                  "event": "videoView",
                  "eventCategory": this.modalData.title,
                  "eventAction": this.modalData.src,
                  "eventLabel": ""
                };
              }
              this.googleSvc.sendGAEventByData(aevent);
            }, 1);
          }

          // @analytics
          // Send GA event when opening a modal.
          // if (this.modalVisible && !this.skipNextGA) {
          //   let ga_id = "";
          //   if (data.modal_type === "component" && data.component) {
          //     ga_id = data.component;
          //     /*} else if (data.modal_type === "sub-menu") {
          //     ga_id = "links";*/
          //   } else {
          //     ga_id = data.modal_type;
          //   }
          //   /*
          //   if (!data.parent && data.modal_type !== "sub-menu") {
          //     // Send sub-menu event first
          //     // May possibly send in header.component.ts instead
          //     this.googleSvc.sendGAEventById("sub-menu", {
          //       eventCategory: data.ga_title ? data.ga_title : data.title,
          //       eventLabel: data['src'] ? data['src'] : '',
          //     });
          //   }
          //   */
          //   if (ga_id === "upcoming_events" || ga_id === "disease_education") {
          //     this.googleSvc.sendGAEventById(ga_id, {
          //       eventLabel: data.title,
          //     });
          //   } else if (this.modalData?.modal_type === "iframe") {
          //     this.googleSvc.sendGAEventById("iframe-modal", {
          //       eventCategory: data.title,
          //       eventLabel: data["src"] ? data["src"] : "",
          //     });
          //   } else {
          //     this.googleSvc.sendGAEventById(ga_id, {
          //       eventCategory: data.title,
          //       eventLabel: data["src"] ? data["src"] : "",
          //     });
          //   }
          // }

          // Reset GA skip
          if (this.modalVisible) {
            this.skipNextGA = false;
          }
        } else {
          setTimeout(() => {
            this.modalData = data;
          }, 350);
        }
      }
    });
  }

  public handleIframe(src): any {
    return this._sanitizer.bypassSecurityTrustResourceUrl(src);
  }

  public closeClick(parent = undefined): void {
    /* @analytics this.googleSvc.sendGAEventById("modal-closed", {
      eventCategory: this.modalData.modal_type,
      eventLabel: this.modalData.title,
    }); */
    if (parent) {
      this.skipNextGA = true;
      this.modalSvc.setActiveModalById(parent);
      this.modalData.parent = undefined;
    } else {
      /* @analytics this.googleSvc.sendGAEventById("modal-closed", {
        eventCategory: this.modalData.modal_type,
        eventLabel: this.modalData.title,
      }); */
      this.modalSvc.hideModal();
    }
  }

  public subMenuClick(item, parent): void {
    if (item.modal) {
      const data: Modal = {
        ...Modals.find((el) => el.id === item.modal),
      } as Modal;
      data.parent = parent;
      if (item.payload) {
        data.payload = item.payload;
      }
      if (item.gtmTitle) {
        this.googleSvc.sendMenuHotspotEvent(item);
      }
      this.modalSvc.setActiveModalByData(data);
    } else if (item.href) {
      // @analytics
      this.googleSvc.sendExternalLinkEvent(item.title, item.href);
      window.open(item.href);
    }
  }

  public columnClick(e, col, parent): void {
    e.preventDefault();

    let data;

    if (col.pdf_path) {
      data = {
        parent: parent,
        modal_type: "pdf",
        pdfSrc: this.path + col.pdf_path,
        gtm_id: col.gtm_id ? col.gtm_id : null,
      };
    } else if (col.video_player) {
      const vids = videos.find((mod) => mod.id === col.video_player);
      const curVid = vids.videos.find((mod) => mod.id === col.video_id);
      data = {
        active_video: curVid,
        modal_type: "video-player",
        id: col.video_id,
        parent,
      };
    } else {
      data = Modals.find((el) => el.id === col.modal);
      if (parent) {
        data.parent = parent;
      } // END IF
    }

    console.log(data);

    this.modalSvc.modalData.next(data);
  }

  public imageHotSpotClick(e): void {
    if (this.modalData?.hotspots) {
      // console.log('imageHotSpotClick, e', e);
      // console.log('imageHotSpotClick, OFFSET', e.offsetX, e.offsetY);
      // console.log('imageHotSpotClick, spots', this.modalData.hotSpots);
      const ratio = e.target.width / this.modalData.image_width;
      const oX = e.offsetX / ratio;
      const oY = e.offsetY / ratio;

      this.modalData.hotspots.forEach((spot) => {
        // console.log('SPOT', spot.x, spot.y, spot.w, spot.h, ratio, oX, oY);

        if (oX > spot.x && oX < spot.x + spot.w) {
          if (oY > spot.y && oY < spot.y + spot.h) {
            // console.log('WE CLICKED IT!!!', spot.id);
            this.modalSvc.modalData.next(
              Modals.find((modal) => modal.id === spot.id)
            );
            // const data = {
            //   ...spot,
            //   parent: this.modalData.id
            // }
            // this.modalSvc.modalData.next(data);
          }
        }
      });
    }
  }

  public nextPdfSlideClick() {
    if (this.currentPdfFileNumber < this.pdfFilesCount - 1) {
      this.currentPdfFileNumber++;
    } else {
      this.currentPdfFileNumber = 0;
    }
  }

  public prevPdfSlideClick() {
    if (this.currentPdfFileNumber > 0) {
      this.currentPdfFileNumber--;
    } else {
      this.currentPdfFileNumber = this.pdfFilesCount - 1;
    }
  }

  public exitClick(bSurvey): void {
    // window.location.href = (bSurvey) ? 'https://survey-d.dynata.com/survey/selfserve/53b/2010541' : this.redirectUrl;
    window.location.href = this.redirectUrl;
  }

  public openModal(id: any): void {
    let data: Modal = Modals.find((el) => el.id === id);
    this.modalSvc.setActiveModalByData(data);
  }

  public externalLink(e, link, trackingId: number = -1): void {
    e.preventDefault();
    if (link === "" && e.target && e.target.href) {
      link = e.target.href;
    }
    if (link !== "") {
      console.log(link, "is the outboundLink");
      //GTM.sendGTMEvent(trackingId);
      // @analytics
      this.googleSvc.sendGAEventById("outboundLink", {
        eventCategory: link,
        eventLabel: "",
      });
      window.open(link, "_blank");
    }
  }
}
