export enum ModalType {
  VideoPlayer = "video-player",
  LiveStream = "live-stream",
  Slideshow = "slideshow",
  IFrame = "iframe",
  PDF = "pdf",
  Image = "image",
  SubMenu = "sub-menu",
  Exit = "exit",
  Thubmnails = "thumbnails",
  HTML = "html",
  HTMLInfo = "html-info",
  Component = "component",
  ResourceLibrary = "resource-library",
  MedForm = "med-form",
}

export interface Modal {
  id: string;
  modal_type: ModalType | string;
  modal_sub_type?: string;
  modal_style?: string;
  title?: string;
  gtmTitle?: string;
  html?: string;
  component?: string;
  payload?: any;
  veeva_code?: string;
  hide_backdrop?: boolean;
  src?: string;
  src_set?: string[];
  poster?: string;
  url?: string;
  url_path?: string;
  exitUrl?: string;
  gtm_id?: number;
  parent?: string;
  image_width?: number;
  disallow_pdf_download?: boolean;
  download_file_name?: string;
  hide_user_controls?: boolean;
  coming_soon?: boolean;
  zoom?: string;
  showLogo?: boolean;

  menuItems?: Array<MenuItem>;

  download_link?: {
    href: string;
    text: string;
    trackingId?: number;
  };

  basePath?: string;
  baseName?: string;
  numSlide?: number;
  questionId?: number;

  hotspots?: Array<ModalHotspot>;
}

export interface MenuItem {
  text: string;
  image?: string;
  type?: string;
  modal?: string;
  href?: string;
  payload?: any;
  children?: any;
}

export interface ModalHotspot {
  id?: string;
  x: number;
  y: number;
  w: number;
  h: number;
  link?: {
    type: string;
    url: string;
  };
  slideId?: number;
  slideWidth?: number;
}
