import { Component, OnInit } from '@angular/core';
import {
  PhotosphereService,
  ModalService,
  HeaderService,
  GoogleService,
} from '../../services';
import * as GTM from '../../utils/GTM';
import Photospheres from '../../../assets/data/base/photospheres.base.json';
import Hotspots from '../../../assets/data/base/hotspotData.base.json';
import Modals from '../../../assets/data/base/modals.base.json';
import Videos from '../../../assets/data/base/videos.base.json';
import { Hotspot, Photosphere } from 'src/app/models';

enum HeaderState {
  Home = 'home-state',
  Portal2 = 'portal2-state',
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  public giDirection: boolean = false;
  public researchDirection: boolean = false;
  public isModalOpen: boolean = false;
  public showHeader: boolean = false;
  public state: any = HeaderState.Portal2;
  public modal: string;

  currentModal: any;
  currentPhotosphere: Photosphere;

  constructor(
    private photosphereSvc: PhotosphereService,
    private modalSvc: ModalService,
    private headerSvc: HeaderService,
    private googleSvc: GoogleService
  ) {}

  ngOnInit(): void {
    this.subscribe();
  }

  subscribe(): void {
    this.modalSvc.modalData.subscribe((data) => {
      this.currentModal = data;
      if (this.currentModal) {
        console.log(this.currentModal);
        this.modal = this.currentModal.id;
        this.setState();
        console.log(this.modal);
      }
    });

    this.modalSvc.modalVisible.subscribe((data) => {
      this.isModalOpen = data;
    });

    this.photosphereSvc.currentPhotosphere.subscribe((data) => {
      this.currentPhotosphere = data;
      this.setState();
    });

    this.photosphereSvc.currentRotation.subscribe((data) => {
      if (data > 150 && data < 300) {
        this.researchDirection = true;
        this.giDirection = false;
      } else {
        this.giDirection = true;
        this.researchDirection = false;
      }

      this.setState();
    });
  }

  setState() {
    //if (this.isModalOpen) {
    if (this.currentPhotosphere?.showHeader) {
      this.state = HeaderState.Portal2;
      this.showHeader = true;
    } else {
      this.state = '';
    }
    // } else {
    //   this.showHeader = false;
    //   this.state = '';
    // }

    this.headerSvc.setHeaderState(this.state);
  }

  public home() {
    this.setPhotosphere("hotspot.global.goto.center");
    this.modalSvc.hideModal();
  }

  public setPhotosphere(hotspotId): void {
    const hotspot = Hotspots.find((hs) => hs.id === hotspotId);
    this.photosphereSvc.setCurrentPhotosphere(
      hotspot.photosphere,
      hotspot.photosphereRotation,
      true
    );

    if (hotspot?.gtm_id) {
      GTM.sendGTMEvent(hotspot.gtm_id);
    }
  }

  public getHotspotData(hotspotDataId: string) {
    return Hotspots.find((el) => el.id === hotspotDataId);
  }

  public modalBelongToMenuItem(
    currentModalId: string,
    hotspotModalId: string
  ): boolean {
    return (
      currentModalId && hotspotModalId && currentModalId === hotspotModalId
    );
  }

  public modalChildOf(currentModalId: string, hotspotModalId: string): boolean {
    if (!currentModalId || !hotspotModalId) return false;

    const hotspotModal = Modals.find((modal) => modal.id === hotspotModalId);

    if (!hotspotModal || !hotspotModal.menuItems) return false;

    const menuItems = hotspotModal.menuItems as any[];

    return menuItems.find((menuItem) => menuItem.modal == currentModalId)
      ? true
      : false;
  }

  public setModalById(hotspotDataId: string, psHotspot: any): void {
    const hotspotData: any = Hotspots.find((el) => el.id === hotspotDataId);
    // @analytics
    if (hotspotData && this.currentPhotosphere && this.currentPhotosphere.hotspots) {
      const hotspot: Hotspot = this.currentPhotosphere.hotspots.find((el) => el.dataId === hotspotDataId);
      if (hotspot) {
        this.googleSvc.sendHotspotEvent(hotspot);
      }
    }
    /*if (psHotspot) {  // May possibly send sub-menu event here instead of modal.component.ts
      this.googleSvc.sendGAEventById("sub-menu", {  // Send sub-menu GA event when header items are clicked
        eventCategory: psHotspot.menuItem,
        eventLabel: "",
      });
    }*/

    this.modalSvc.setActiveModalById(hotspotData.modal);
  }
}
