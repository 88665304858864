export const commonEnv = {
  assetBasePath: "",
  showName: "sleep2022",
  fov: 75,
  persistentMenu: false,
  menuButtonType: "default",
  menuTitle: "Product Portfolio",
  menuType: "default",
  menuTooltips: false,
  footerType: "default",
  disablePhotosphereRotate: true,
  showHomeLink: false,
  homeLinkPhotosphereId: "home",
  showExitLink: true,
  exitLinkType: "photosphere",
  exitLinkValue: "center",
  showMenuButtonWhenHeaderVisible: false,
  autoOpenMenuOnHome: false,
  welcomeSettings: {
    countryGate: true,
    rememberHcpGate: true,
    title: "Welcome to the<br />Takeda Virtual Experience",
    veevaCode: "VV-MEDMAT-57704 03/2022",
    disclaimer:
      "The information on this website is intended for Healthcare Professionals outside the UK and US.",
    footerCopy:
      "Copyright ©️ 2022 Takeda Pharmaceutical Company Limited. All rights reserved. \nTAKEDA and the TAKEDA logo are registered trademarks of Takeda Pharmaceutical Company Limited.",
    showFooter: true,
    redirectNotice: "The information is intended for Healthcare Professionals.",
  },
  footerCopy:
    "The information on this website is intended for Healthcare Professionals outside the UK and US. Takeda does not have any approved treatments for narcolepsy. Copyright ©️ 2022 Takeda Pharmaceutical Company Limited.  All rights reserved. TAKEDA and the TAKEDA logo are registered trademarks of Takeda Pharmaceutical Company Limited.",
  contentUrlRules: [
    {
      targetHosts: ["localhost", "sleep2022-stage.websitemagic.dev"],
      contentUrl:
        "https://sleep2022-stage.websitemagic.dev/assets/final-content",
    },
    {
      targetHosts: ["sleep.medical.takedaneuroscience.com"],
      contentUrl:
        "https://sleep.medical.takedaneuroscience.com/assets/final-content",
    },
  ],
};
